import Cookies from 'js-cookie'
import { Permissions } from '@/types/organization'

const TokenKey: string = 'token'

export const getToken = (): string | undefined => {
  return Cookies.get(TokenKey)
}

export const setToken = (token: string): string | undefined => {
  return Cookies.set(TokenKey, token)
}

export const removeToken = (): void => {
  return Cookies.remove(TokenKey)
}

export const getPublicKey = (): string => {
  const publicKey =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmYyUODlTnFqRZEvzU0cjVh0u53ERaUKwL4omekZY/p09srSr+vOdo9AbL9PRv5bD3KZzHMV3NH3aA+MEbzaqnS5rhNlY6SSuUxQ3Ck+KLfQYeZlXxI/1WuqyLPC5ffUCdMtPELjr3SX3jbRzz12KhsTGcbL++LBuabbwjEoLqUfG7KDZLk5GJEVJWrcy+GOFIgI1gl3LsWuGSRAWPt4F9x7xcrqNDDeS7PItd9/l1Qdl0jnxlfqlxZvGArvwSxB7Yv8+E4ByrJQywRCVfPoejmunrSxczEMw9RPgObWn9SxTKrqAxtX3EDc9y7wSD0sRbN6+gzrP/gQUlIpQt78KCQIDAQAB'
  return publicKey
}

// 判断是否拥有访问权限
export const hasAccess = (permission?: Permissions): boolean => {
  let user: any = localStorage.getItem('user')
  user = !!user ? JSON.parse(user) : null

  if (!user) return false

  if (!permission) return false

  if (!(user.access instanceof Array)) return false

  const access = user.access.includes(permission)

  return access
}

// 判断是否拥有角色权限
export const hasRoleAccess = (permission?: string): boolean => {
  let user: any = localStorage.getItem('user')
  user = !!user ? JSON.parse(user) : null

  if (!user) return false

  if (!permission) return false

  if (!(user.roles instanceof Array)) return false

  const access = user.roles.includes(permission)

  return access
}
