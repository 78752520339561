import axios from '@/utils/request'

// 新增机构 (平台使用)
export const tenantAdd = (data: any) => {
  return axios.request({
    url: '/tenant/add',
    data,
    method: 'post'
  })
}

// 修改机构 (平台使用)
export const tenantUpdate = (data: any) => {
  return axios.request({
    url: '/tenant/update',
    data,
    method: 'post'
  })
}

// 删除机构 (平台使用)
export const tenantDelete = (data: any) => {
  return axios.request({
    url: '/tenant/delete',
    data,
    method: 'post'
  })
}

// 查询机构详情
export const tenantDetail = (data: any) => {
  return axios.request({
    url: '/tenant/detail',
    data,
    method: 'post'
  })
}

// 分页查询机构列表 (平台使用)
export const tenantPage = (data: any) => {
  return axios.request({
    url: '/tenant/page',
    data,
    method: 'post'
  })
}

// 新增权限 (平台使用)
export const permissionPlatAdd = (data: any) => {
  return axios.request({
    url: '/tenant/permission/plat/add',
    data,
    method: 'post'
  })
}

// 修改权限 (平台使用)
export const permissionPlatUpdate = (data: any) => {
  return axios.request({
    url: '/tenant/permission/plat/update',
    data,
    method: 'post'
  })
}

// 删除权限 (平台使用)
export const permissionPlatDelete = (data: any) => {
  return axios.request({
    url: '/tenant/permission/plat/delete',
    data,
    method: 'post'
  })
}

// 查询平台下所有的权限列表 (平台使用)
export const permissionPlatList = (data: any) => {
  return axios.request({
    url: '/tenant/permission/plat/list',
    data,
    method: 'post'
  })
}

// 新增角色 (平台使用)
export const tenantRolePlatAdd = (data: any) => {
  return axios.request({
    url: '/tenant/role/plat/add',
    data,
    method: 'post'
  })
}

// 删除角色 (平台使用)
export const tenantRolePlatDelete = (data: any) => {
  return axios.request({
    url: '/tenant/role/plat/delete',
    data,
    method: 'post'
  })
}

// 查询平台下所有的角色列表 (平台使用)
export const tenantRolePlatList = (data: any) => {
  return axios.request({
    url: '/tenant/role/plat/list',
    data,
    method: 'post'
  })
}

// 新增员工
export const employeeAdd = (data: any) => {
  return axios.request({
    url: '/tenant/employee/add',
    data,
    method: 'post'
  })
}

// 给员工分配角色
export const employeeAuthorize = (data: any) => {
  return axios.request({
    url: '/tenant/employee/authorize',
    data,
    method: 'post'
  })
}

// 删除员工
export const employeeDelete = (data: any) => {
  return axios.request({
    url: '/tenant/employee/delete',
    data,
    method: 'post'
  })
}

// 查询员工拥有的权限列表
export const employeeAccessedList = (data: any) => {
  return axios.request({
    url: '/tenant/employee/listAccessedPermissions',
    data,
    method: 'post'
  })
}

// 分页查询员工
export const employeePage = (data: any) => {
  return axios.request({
    url: '/tenant/employee/page',
    data,
    method: 'post'
  })
}

// 修改员工信息
export const employeeUpdate = (data: any) => {
  return axios.request({
    url: '/tenant/employee/update',
    data,
    method: 'post'
  })
}

// 查询租户 (机构) 被分配的可以使用的角色列表
export const tenantRoleList = (data: any) => {
  return axios.request({
    url: '/tenant/role/list',
    data,
    method: 'post'
  })
}

// 分页查询已入驻机构列表
export const tenantSupplierPage = (data: any) => {
  return axios.request({
    url: '/tenant/supplier/page',
    data,
    method: 'post'
  })
}

// 供应商入驻
export const tenantSupplierSettle = (data: any) => {
  return axios.request({
    url: '/tenant/supplier/settle',
    data,
    method: 'post'
  })
}

// 移除已入驻供应商
export const tenantSupplierUnsettle = (data: any) => {
  return axios.request({
    url: '/tenant/supplier/unsettle',
    data,
    method: 'post'
  })
}

// 查询登录人拥有的权限列表
export const listAccessedPermissions = (data: any) => {
  return axios.request({
    url: '/tenant/employee/listAccessedPermissions',
    data,
    method: 'post'
  })
}

// 查询当前登录用户的租户详情
export const tenantUserDetail = (data: any) => {
  return axios.request({
    url: '/tenant/user/detail',
    data,
    method: 'post'
  })
}

// 查询当前登录用户的租户详情
export const employeeDetail = (data: any) => {
  return axios.request({
    url: '/tenant/employee/detail',
    data,
    method: 'post'
  })
}
