import { AnyAction, EffectsCommandMap } from '@umijs/max'
import type { Effect, Reducer } from '@umijs/max'
import { MapAccessToRoles } from '@/types/organization'
import type { OrgnzDetail, OrgnzRoles } from '@/types/organization'
import { tenantUserDetail, listAccessedPermissions } from '@/api/tenant'

interface ModelState {
  orgnzInfo: OrgnzDetail
}

interface ModelType {
  namespace: string
  state?: ModelState
  reducers?: {
    setOrgnzInfo: Reducer<ModelState, AnyAction>
  }
  effects?: {
    getOrgnzInfo: Effect
  }
  subscriptions?: any
}

const initialState: ModelState = {
  orgnzInfo: {
    code: ''
  }
}

const OrgnzModel: ModelType = {
  namespace: 'orgnz',
  state: initialState,
  reducers: {
    setOrgnzInfo: (state: ModelState, { payload }: AnyAction) => ({
      ...state,
      orgnzInfo: { ...state.orgnzInfo, ...payload }
    })
  },
  effects: {
    // 获取用户所在机构信息
    *getOrgnzInfo({}: AnyAction, { put, call }: EffectsCommandMap): any {
      const orgnzRes: any = yield call(tenantUserDetail, {})

      if (!!orgnzRes && !!orgnzRes.code) {
        let roles: Array<OrgnzRoles> = []
        const tempArr = orgnzRes.roleCodes.map((role: OrgnzRoles) => {
          let access: Array<string> = []
          roles.push(role)

          if (!!MapAccessToRoles[role]) {
            access = MapAccessToRoles[role]
          }

          return access
        })

        const accesses: Array<unknown> = [...new Set(tempArr.toString().split(','))]
        const info = {
          name: orgnzRes.name,
          code: orgnzRes.code,
          createTime: orgnzRes.createTime,
          adminName: orgnzRes.adminName,
          adminPhone: orgnzRes.adminPhone,
          level: orgnzRes.level,
          type: orgnzRes.type,
          description: orgnzRes.description,
          cover: orgnzRes.cover,
          roleCodes: orgnzRes.roleCodes
        }
        yield put({ type: 'setOrgnzInfo', payload: info })

        //查询用户权限
        const accessRes: unknown = yield call(listAccessedPermissions, {
          permissionCodes: accesses
        })

        if (!!accessRes && accessRes instanceof Array) {
          yield put({
            type: 'user/updateUserInfo',
            payload: { access: accessRes, roles }
          })
        }
      }
    }
  },
  subscriptions: {}
}

export default OrgnzModel
