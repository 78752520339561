// 入驻机构类型
export enum OrgnzType {
  PLAT = 'PLAT', // 平台 (一般仅鸿云中芯)
  PUB_INST = 'PUBIC_INSTITUTION', // 事业单位
  SCI_RES = 'SCIENTIFIC_RESEARCH_INSTITUTION', // 科研机构
  BIZ = 'BUSINESS' // 合作商户
}

// 机构角色
export enum OrgnzRoles {
  PLAT_ADMIN = 'PLAT_ADMIN', // 平台超级管理员
  PLAT_SUB_ADMIN = 'PLAT_SUB_ADMIN', // 平台子管理员
  PUB_INST_ADMIN = 'PUBIC_INSTITUTION_ADMIN', // 事业单位主管理员
  PUB_INST_SUB_ADMIN = 'PUBIC_INSTITUTION_SUB_ADMIN', // 事业单位子管理员
  PUB_INST_STUFF = 'PUBIC_INSTITUTION_STUFF', // 事业单位员工
  SCI_RES_ADMIN = 'SCIENTIFIC_RESEARCH_INSTITUTION_ADMIN', // 科研机构主管理员
  SCI_RES_SUB_ADMIN = 'SCIENTIFIC_RESEARCH_INSTITUTION_SUB_ADMIN', // 科研机构子管理员
  SCI_RES_STUFF = 'SCIENTIFIC_RESEARCH_INSTITUTION_STUFF', // 科研机构员工
  BIZ_ADMIN = 'BUSINESS_ADMIN', // 合作商户主管理员
  BIZ_STUFF = 'BUSINESS_STUFF' // 合作商户员工
}

// 角色权限
export enum Permissions {
  TENANT_ADD = 'TENANT_ADD', // 添加机构信息
  TENANT_EDIT = 'TENANT_EDIT', // 修改机构信息
  TENANT_DELETE = 'TENANT_DELETE', // 删除机构信息
  SUPPLIER_ADD = 'SUPPLIER_ADD', // 添加供应商
  SUPPLIER_DELETE = 'SUPPLIER_DELETE', // 移除已入驻的供应商
  STUFF_ADD = 'STUFF_ADD', // 添加成员
  STUFF_EDIT = 'STUFF_EDIT', // 修改成员
  STUFF_DELETE = 'STUFF_DELETE', // 删除成员
  ACTIVITY_ADD = 'ACTIVITY_ADD', // 发布活动
  ACTIVITY_EDIT = 'ACTIVITY_EDIT', // 修改活动
  ACTIVITY_DELETE = 'ACTIVITY_DELETE', // 删除活动
  ACTIVITY_CHECK_IN = 'ACTIVITY_CHECK_IN' // 活动扫码核销
}

// 角色权限映类型
export type AccessToRolesType = {
  [key: string]: Array<Permissions>
}

// 角色权限映射表
export const MapAccessToRoles: AccessToRolesType = {
  // 平台超级管理员
  PLAT_ADMIN: [
    Permissions.TENANT_ADD,
    Permissions.TENANT_DELETE,
    Permissions.TENANT_EDIT,
    Permissions.STUFF_ADD,
    Permissions.STUFF_EDIT,
    Permissions.STUFF_DELETE,
    Permissions.ACTIVITY_ADD,
    Permissions.ACTIVITY_EDIT,
    Permissions.ACTIVITY_DELETE,
    Permissions.ACTIVITY_CHECK_IN
  ],
  // 平台子管理员
  PLAT_SUB_ADMIN: [
    Permissions.ACTIVITY_ADD,
    Permissions.ACTIVITY_EDIT,
    Permissions.ACTIVITY_DELETE,
    Permissions.ACTIVITY_CHECK_IN
  ],
  // 事业单位主管理员
  PUBIC_INSTITUTION_ADMIN: [
    Permissions.TENANT_EDIT,
    Permissions.SUPPLIER_ADD,
    Permissions.SUPPLIER_DELETE,
    Permissions.STUFF_ADD,
    Permissions.STUFF_EDIT,
    Permissions.STUFF_DELETE,
    Permissions.ACTIVITY_ADD,
    Permissions.ACTIVITY_EDIT,
    Permissions.ACTIVITY_DELETE,
    Permissions.ACTIVITY_CHECK_IN
  ],
  // 事业单位子管理员
  PUBIC_INSTITUTION_SUB_ADMIN: [
    Permissions.ACTIVITY_ADD,
    Permissions.ACTIVITY_EDIT,
    Permissions.ACTIVITY_DELETE,
    Permissions.ACTIVITY_CHECK_IN
  ],
  // 事业单位员工
  PUBIC_INSTITUTION_STUFF: [Permissions.ACTIVITY_CHECK_IN],
  // 科研机构主管理员
  SCIENTIFIC_RESEARCH_INSTITUTION_ADMIN: [
    Permissions.TENANT_EDIT,
    Permissions.SUPPLIER_ADD,
    Permissions.SUPPLIER_DELETE,
    Permissions.STUFF_ADD,
    Permissions.STUFF_EDIT,
    Permissions.STUFF_DELETE,
    Permissions.ACTIVITY_ADD,
    Permissions.ACTIVITY_EDIT,
    Permissions.ACTIVITY_DELETE,
    Permissions.ACTIVITY_CHECK_IN
  ],
  // 科研机构子管理员
  SCIENTIFIC_RESEARCH_INSTITUTION_SUB_ADMIN: [
    Permissions.ACTIVITY_ADD,
    Permissions.ACTIVITY_EDIT,
    Permissions.ACTIVITY_DELETE,
    Permissions.ACTIVITY_CHECK_IN
  ],
  // 科研机构员工
  SCIENTIFIC_RESEARCH_INSTITUTION_STUFF: [Permissions.ACTIVITY_CHECK_IN],
  // 合作商户主管理员
  BUSINESS_ADMIN: [
    Permissions.TENANT_EDIT,
    Permissions.STUFF_ADD,
    Permissions.STUFF_EDIT,
    Permissions.STUFF_DELETE,
    Permissions.ACTIVITY_ADD,
    Permissions.ACTIVITY_EDIT,
    Permissions.ACTIVITY_DELETE,
    Permissions.ACTIVITY_CHECK_IN
  ],
  // 合作商户员工
  BUSINESS_STUFF: [Permissions.ACTIVITY_CHECK_IN]
}

// 机构详情信息
export type OrgnzDetail = {
  name?: string // 机构名称
  code?: string // 机构id
  createTime?: string // 创建时间
  adminName?: string // 主管理员昵称
  adminPhone?: string // 主管理员手机号
  level?: number // 机构等级
  type?: OrgnzType // 机构类型
  description?: string // 机构描述
  cover?: Array<string> // 机构封面、图标
  roleCodes?: Array<string> // 机构可分配角色
}
