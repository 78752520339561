import HttpRequest from './axios'

let BASE_URL = ''

if (window.location.href.startsWith('https://testpanel')) {
  BASE_URL = 'https://testpanel.celesvue.com/api'
} else if (window.location.href.startsWith('https://panel')) {
  BASE_URL = 'https://panel.celesvue.com/api'
} else if (window.location.href.startsWith('https://cloud')) {
  BASE_URL = 'https://cloud.celesvue.com/api'
} else {
  BASE_URL = '/api'
}

const axios = new HttpRequest(BASE_URL)

const mapAxios = new HttpRequest('/mapApi')

export { BASE_URL, axios as default, mapAxios }
