import { AnyAction, EffectsCommandMap } from '@umijs/max'
import type { Effect, Reducer } from '@umijs/max'
import type { UserInfo } from '@/types/user'
import { queryUserInfo } from '@/api/user'

interface ModelState {
  userInfo: UserInfo
}

interface ModelType {
  namespace: string
  state?: ModelState
  reducers?: {
    updateUserInfo: Reducer<ModelState, AnyAction>
    removeUserInfo: Reducer<ModelState>
  }
  effects?: {
    getUserInfo: Effect
  }
  subscriptions?: any
}

const initialState: ModelState = {
  userInfo: {
    avatar: '',
    uid: '', // 用户id 唯一
    name: '', // 用户昵称
    phone: '', // 手机号
    areaCode: '86', // 地区码 默认86
    mail: '', // 邮箱地址
    wechat: '', // 微信id
    userSign: '', // 个性签名
    sex: 1, // 0-女 1-男 2-扶她 (bushi
    signEnable: 0, // 是否已签到 0-未签到 1-已签到
    userLevel: 0, // 用户级别 默认0
    fansNum: 0, // 粉丝数
    followNum: 0, // 关注数
    fondNum: 0, // 点赞数
    imageMark: 0, // 图像水印 0-关 1-开
    integralNum: 0, // 积分值
    access: null, // 用户权限列表
    roles: null // 用户在机构内的角色
  }
}

const UserModel: ModelType = {
  namespace: 'user',
  state: initialState,
  reducers: {
    updateUserInfo: (state: ModelState, { payload }: AnyAction) => {
      const info = { ...state.userInfo, ...payload }
      localStorage.setItem('user', JSON.stringify(info))

      return {
        ...state,
        userInfo: info
      }
    },
    removeUserInfo: (state: ModelState) => {
      localStorage.removeItem('user')

      return {
        ...state,
        userInfo: initialState.userInfo
      }
    }
  },
  effects: {
    // 获取个人信息
    *getUserInfo({}: AnyAction, { put, call }: EffectsCommandMap): any {
      const userRes: any = yield call(queryUserInfo) as any

      if (!!userRes) {
        const userInfo = {
          avatar: userRes.avatar || '',
          signEnable: userRes.signEnable,
          uid: userRes.customerId,
          name: userRes.customerName,
          phone: userRes.phone,
          sex: userRes.sex,
          userLevel: userRes.userLevel,
          userSign: userRes.userSign,
          wechat: userRes.wechatId,
          mail: userRes.email,
          fansNum: userRes.fansNum,
          followNum: userRes.followNum,
          fondNum: userRes.fondNum,
          imageMark: userRes.imageMark,
          integralNum: userRes.integralNum
        } as UserInfo

        yield put({ type: 'updateUserInfo', payload: userInfo })
      }
    }
  },
  subscriptions: {}
}

export default UserModel
