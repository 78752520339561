// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from 'D:/workSpace/hysmic/hysmic-stargazing-management/src/models/organization';
import model_2 from 'D:/workSpace/hysmic/hysmic-stargazing-management/src/models/user';

export const models = {
model_1: { namespace: 'organization', model: model_1 },
model_2: { namespace: 'user', model: model_2 },
} as const
