import axios from '@/utils/request'

// 个人信息查询
export const queryUserInfo = () => {
  return axios.request({
    url: '/info/queryUserInfo',
    method: 'get'
  })
}

// 个人资料修改
export const updateCustInfo = (data: any) => {
  return axios.request({
    url: '/info/updateCustInfo',
    data,
    method: 'post'
  })
}

// 用户签到
export const userSignDate = () => {
  return axios.request({
    url: '/info/userSignDate',
    method: 'get'
  })
}

// 用户名单查询
export const userRelation = (type: string, params?: any) => {
  return axios.request({
    url: `/info/queryUserRelation/${type}`, // 0-关注 1-黑名单 2-粉丝
    params,
    method: 'get'
  })
}

// 修改手机号
export const changeUserPhone = (data: any) => {
  return axios.request({
    url: '/info/changeUserPhone',
    data,
    method: 'post'
  })
}

// 修改密码
export const changeUserPwd = (data: any) => {
  return axios.request({
    url: '/info/changeUserPwd',
    data,
    method: 'post'
  })
}

// 注销账户
export const logOut = (data: any) => {
  return axios.request({
    url: '/info/logOut',
    data,
    method: 'post'
  })
}
